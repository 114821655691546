/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* You can add global styles to this file, and also import other style files */
@import "./theme/custom.scss";
@import "./theme/font-breakpoints";

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

html {
  height: 100%;
}

body {
  background-color: #efefef;
}

ion-modal.config-default { // workign
  --max-height: 100%;
  --max-width: 100%;
  --width: 100%;
  --height: 100%;
  --border: none;
  --border-radius: none;
  --border-style: none;
  --border-color: none;
  --border-width: none;
}

.config-default {  // Modal cssClass class name - working
  --backdrop-opacity: 0;
}
