ion-modal {
  --border-radius: 14px;

  &.full-width-modal {
    --width: 100%;
    --height: 100%;
  }
  &.full-wi {
    --width: 100%;
  }

  &.medium-width-modal {
    --width: 500px;

    @media only screen and (min-width: 768px) and (min-height: 600px) {
      --height: 620px;
    }
  }

  &.default-modal {
    @media only screen and (min-width: 768px) and (min-height: 600px) {
      --height: 380px;
    }
  }
}
